import { useState, useEffect } from "react";

type DeviceType = "mobile" | "tablet" | "desktop";

const useDeviceTypeWithUserAgent = (): DeviceType => {
  const [deviceType, setDeviceType] = useState<DeviceType>("desktop");

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (/mobile|android|iphone|ipod/.test(userAgent)) {
      setDeviceType("mobile");
    } else if (/ipad|tablet/.test(userAgent)) {
      setDeviceType("tablet");
    } else {
      setDeviceType("desktop");
    }
  }, []);

  return deviceType;
};

export default useDeviceTypeWithUserAgent;