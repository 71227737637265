import { useState, useEffect } from "react";
import useIPAddress from "./useIPAddress";

interface Location {
  latitude: number | null;
  longitude: number | null;
}

const useGeolocation = () => {
  const [location, setLocation] = useState<Location>({
    latitude: null,
    longitude: null,
  });
  const [error, setError] = useState<string | null>(null);

  const updateLocation = () => {
    if (!navigator.geolocation) {
      setError("Geolocation is not supported by this browser.");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log('position::',position)
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        setError(null); // Limpia errores previos si la operación fue exitosa
      },
      (err) => {
        setError(`Error: ${err.message}`);
      }
    );
  };

  // Efecto para actualizar la ubicación al montar el componente
  useEffect(() => {
    updateLocation();
  }, []);

  return { location, error, updateLocation };
};

export default useGeolocation;
