import { useState, useEffect } from "react";

const useIPAddress = () => {
  const [ip, setIp] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIp(data.ip);
      } catch (err) {
        setError("Failed to fetch IP address.");
        console.error(err);
      }
    };

    fetchIP();
  }, []);

  return { ip, error };
};

export default useIPAddress;
